const RoundCornerDiv = ({ width, height, corner, color, shadowSize = 0, className, containerStyle, ...props }) => {
  return (
    <div 
      className={className}
      style={{
        width: width,
        height: height ? height : width,
        borderRadius: corner ? corner : width/2,
        backgroundColor: color,
        boxShadow: shadowSize > 0 ? '0 ' + shadowSize + 'px ' + shadowSize*2 + 'px 0 rgba(0, 0, 0, 0.2), 0 ' + shadowSize*1.6 + 'px ' + shadowSize*5 + 'px 0 rgba(0, 0, 0, 0.19)' : 'none',
        ...containerStyle,
      }} 
      {...props} 
    />
  )
}

export default RoundCornerDiv;
