// --------------------------------------------------------------------------------
// This hook will call navigator.mediaDevices.getUserMedia(), and when successfully
// get a camera stream, assign it to a given videoDOM.
// --------------------------------------------------------------------------------
import { useEffect } from 'react'
import DevLog from '../../common/utils/DevLog';

export function useUserMedia (requestedMedia, videoDOM)
{
  const stopMediaStream = () => {
    let mediaStream = window.localStream;
    if (mediaStream) {
      DevLog('[useUserMedia] stopMediaStream');
      mediaStream.getTracks().forEach(track => { track.stop() });
      window.localStream = null;
    }
  }

  useEffect(() => {
    async function enableVideoStream () {
      DevLog('[useUserMedia] enableVideoStream with options:');
      DevLog(requestedMedia);
      try {
        const stream = await navigator.mediaDevices.getUserMedia(requestedMedia);
        window.localStream = stream;
        videoDOM.srcObject = stream;
      } catch (err) { // Handle the error
        console.log('[useUserMedia] error: ', err)
      }
    }

    if (videoDOM === null) return;

    stopMediaStream();
    enableVideoStream();

  // eslint-disable-next-line
  }, [requestedMedia, videoDOM])

  useEffect(() => {
    return function cleanup () {
      DevLog('[useUserMedia] cleanup');
      stopMediaStream();
    }
    // eslint-disable-next-line
  }, []);

  return window.localStream;
}
