// -----------------------------------------------------------------------------
// A React component which call useUserMedia() to create a camera view.  Since camera
// resolution is given by device, it may not be the same as the viewable area.
// We will use "useCoverFit" to cover-fit the camera image onto the viewable area.
// -----------------------------------------------------------------------------
import { useState, useRef, useMemo, forwardRef, useImperativeHandle, useEffect } from 'react'
import { useUserMedia } from '../ccg-reactjs-libs/camera/hooks/useUserMedia'
import { useCoverFit, DevLog } from '../ccg-reactjs-libs/common'
import styled from '@emotion/styled'
import Measure from 'react-measure'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Video = styled.video`
  position: absolute;
  pointer-events: none;
  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
`

// -----------------------------------------------------------------------------
// https://stackoverflow.com/questions/37949981/call-child-method-from-parent
// -----------------------------------------------------------------------------
const CameraView = forwardRef(({ cameraWidth = 1280, cameraHeight = 720, user = false, onVideoStart = null }, ref) => {
  
  const requestOptions = useMemo(() => { return {
    audio: false,
    video: {
      width: { min: 640, ideal: cameraWidth, max: 1920 },
      height: { min: 400, ideal: cameraHeight, max: 1080 },
      facingMode: user ? 'user' : 'environment'
    }
  }}, [cameraWidth, cameraHeight, user]);

  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
  const [videoSize, setVideoSize] = useState({width:0, height:0});
  const videoRef = useRef(null);
  
  // -------------------------------------------------------------
  // this following hook will start streaming from the camera
  // -------------------------------------------------------------
  const mediaStream = useUserMedia(requestOptions, videoRef.current);

  // -------------------------------------------------------------
  // when stream is ready, trigger the play() and store the camera resolution
  // -------------------------------------------------------------
  function handleCanPlay () {
    DevLog('[CameraView] handleCanPlay: videoSize=' + videoRef.current.videoWidth + 'x' + videoRef.current.videoHeight)
    videoRef.current.play();
    setVideoSize({width: videoRef.current.videoWidth, height: videoRef.current.videoHeight});  // this one will force a renderer and trigger useCoverFit()
    if (onVideoStart) onVideoStart();
  }

  // -------------------------------------------------------------
  // In the event that the video (v) is larger than it's parent container (c),
  // calculate offsets to center the video in the middle of the container.
  // -------------------------------------------------------------
  const coverFitStyle = useCoverFit(
    videoSize.width,
    videoSize.height,
    containerSize.width,
    containerSize.height
  );

  useImperativeHandle(ref, () => ({
    getCameraWidth: () => { return videoRef.current.videoWidth },
    getCameraHeight: () => { return videoRef.current.videoHeight },
    getCameraStyle: () => { return coverFitStyle },
    getVideoDOM: () => { return videoRef.current },
  }));

  // if (!mediaStream) { return null; } // if no camera, drawing nothing

  // useEffect(()=>{
  //   return function cleanup() {
  //     DevLog('[CameraView] cleanup');
  //     if (mediaStream) {
  //       DevLog('[CameraView] cleanup -- stop media stream');
  //       mediaStream.getTracks().forEach(track => { track.stop() })
  //     }
  //   }
  // })
  // ------------------------------------------------------------------------------------------
  // As my <Container> use 100% width and 100% height of the parent, the <Measure> results
  // "contentRect.bounds.width" and "contentRect.bounds.height" are equals to the bounds of
  // my parent container.  I use setContainerSize() to keep track its size.
  // This will trigger useCoverFit() to center the <Video> element.
  // ------------------------------------------------------------------------------------------
  return (
    <Measure client onResize={(contentRect) => {
      // console.log("contentRect",contentRect);
      // DevLog('[CameraView] handleResize: viewSize=' + contentRect.bounds.width + 'x' + contentRect.bounds.height)
      // setContainerSize({ width: contentRect.bounds.width, height: contentRect.bounds.height })
      DevLog('[CameraView] handleResize: viewSize=' + contentRect.client.width + 'x' + contentRect.client.height)
      setContainerSize({ width: contentRect.client.width, height: contentRect.client.height })
    }}>
      {({ measureRef }) => (
        <Container ref={measureRef}>
          <Video
            ref={videoRef}
            onCanPlay={handleCanPlay}
            autoPlay
            playsInline
            muted
            style={ coverFitStyle.domStyle }
          />
        </Container>
      )}
    </Measure>
  )
})

export default CameraView;