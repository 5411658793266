import './App.css';
import { GlobalStyles, useOrientation, RotateToPortraitView } from './ccg-reactjs-libs/common';
import { useState, useRef } from 'react';
import { WindowSizeProvider, useWindowSizeContext } from './ccg-reactjs-libs/common/contexts/windowSizeContext.js';
import { Scanner } from './Components/Scanner';
import Home from './Components/ui/HomePage';
import QRCodeScannerView from './Components/ui/QRCodeScannerView';
import { qrCodeLinkList } from './assets';
import { bgm } from './assets';


import Div100vh from 'react-div-100vh';

function App() {
  const [showVideo, setShowVideo] = useState(false);
  const [showQRScan, setShowQRScan] = useState(false);
  const audioRef = useRef(null);
  const { rescale } = useWindowSizeContext();
  const orientation = useOrientation();
  const handleChangeVideo = () => {
    setShowVideo(prev => !prev);
  }
  const handleChangeQRScan = (para = true) => {
    if(para === true){
      setShowQRScan(false);
    } else {
      setShowQRScan(prev=> !prev);
    }
  }
  // const onCodeFound = (data) => {
  //   console.log(data);
  //   for(let i = 0; i < qrCodeLinkList.length; i++){
  //     if(data === qrCodeLinkList[i]){
  //       setVideoDisplay(i);
  //       handleChangeVideo();
  //       handleChangeQRScan(true); 
  //       break;
  //     }
  //   }
  // }

  return (
    <>
      <div style={{ overflow: "hidden", position: "absolute", ...rescale.domStyle }}>
        {!showVideo && <Home handleChangeVideo={handleChangeVideo} />}
      </div>
      {/* {showQRScan && <QRCodeScannerView handleChangeVideo={handleChangeVideo} onCodeFound={onCodeFound} />} */}
      {showVideo && <Scanner handleChangeVideo={handleChangeVideo} handleChangeQRScan={handleChangeQRScan} />}
        {/* <audio ref={audioRef} controls autoPlay playsInline loop>
          <source src={bgm} type="audio/mpeg" />
        </audio>
        <button onClick={() => {if(audioRef.current.paused){audioRef.current.play()} else {audioRef.current.pause()}}} style={{ position: 'absolute' }}>hi</button> */}
      <RotateToPortraitView orientation={orientation} />
    </>

  );
}

const AppWrappedWithProvider = () => {
  return (
    <Div100vh>
      <WindowSizeProvider referenceWidth={1080} referenceHeight={1920}>
        <GlobalStyles/>
        <App />
      </WindowSizeProvider>
    </Div100vh>
  )
}

export default AppWrappedWithProvider;
