const loaderStyle = {
    position: "absolute",
    background: "black",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
};

const VideoLoader = () => {
    return ( 
    <div style={{...loaderStyle}}>
        <span className="videoLoad"></span>
    </div>
     );
}
 
export default VideoLoader;