import { BGimage, startBtn, logo, contBtn, intro } from "../../assets";
import { useState } from 'react'
import { useWindowSizeContext } from '../../ccg-reactjs-libs/common/contexts/windowSizeContext.js';

const Home = ({ handleChangeVideo }) => {
    const { rescale } = useWindowSizeContext();
    const [showHome, setShowHome] = useState(true);
    const handleChangeHome = () => {
        setShowHome(prev => !prev);
    }
    return ( 
        <div>
            <img src={BGimage} alt="BG" style={{ position: "absolute", width: "100%", height: "100%" }} />
            {
            showHome && 
            <>
                <img src={logo} alt="logo" style={{ position: "absolute", width: 1003/rescale.scale, height: 640/rescale.scale, top: "20%"}} />
                <div onClick={handleChangeHome} style={{ position: "absolute", width: 349/rescale.scale, height: 166/rescale.scale, top: "57%", left: "33%" }}>
                    <img src={startBtn} alt="start button" style={{ position: "absolute", width: 349/rescale.scale, height: 166/rescale.scale }} />
                </div>
            </> } 
            {!showHome && 
            <>
                <img src={intro} alt="logo" style={{ position: "absolute", width: 992/rescale.scale, height: 598/rescale.scale, top: "20%", left: "5%"}} />
                <div onClick={handleChangeVideo} style={{ position: "absolute", width: 349/rescale.scale, height: 166/rescale.scale, top: "57%", left: "33%" }}>
                    <img src={contBtn} alt="next button" style={{ position: "absolute", width: 349/rescale.scale, height: 166/rescale.scale }} />
                </div>
            </>
            }
        </div>
     );
}
 
export default Home;