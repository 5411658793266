// -------------------------------------------------------------------------
// Example on how to use this file can be found in libs/camera.
// To fit other projects, we can copy this file and modify the parameters.
//
// ** NOTE ** in React 18 this component will "flash" at the beginner and
// end of animation.  We suggest to use other animation library (e.g. react-springs)
// instead of css keyframes.
//
// -------------------------------------------------------------------------
import { forwardRef } from 'react'
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

const ShrunkAnimation = keyframes`
  from { transform: scale(1.0) rotate(0deg) translate(0,0); }
  to   { transform: scale(0.52) rotate(-10deg) translate(0px, 0px); }
`
const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  overflow: hidden;

  border-style: solid;
  border-color: white;
  border-width: 5px;

  ${({ animate }) => {
    if (animate) {
      return css`
        animation: ${ShrunkAnimation} 750ms ease-out;
      `
    } else {
      return css`
        transform: scale(0.52) rotate(-10deg) translate(0px, 0px);
      `
    }
  }}
`
const ImageContainer = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const PhotoPreview = forwardRef(({hidden, animate, onAnimationEnd }, ref) => {
  return (
    <Container 
      hidden={hidden}
      animate={animate}
      onAnimationEnd={onAnimationEnd}
    >
      <ImageContainer alt="photo preview" ref={ref} />
    </Container>
  )
});

export default PhotoPreview;
