// ------------------------------------------------------------------------------
// Define some commonly used styles.
// Note that these global styles will be inserted under the <head> section when
// the component mounts, and will be removed when the component unmounts.
// Name conflict may happen when using these global styles.  So be careful. 
// ------------------------------------------------------------------------------

import { Global, css } from '@emotion/react';

const GlobalStyles = () => {
  return (
    <Global styles={css`
      .fillParent {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      .centerRowContainer {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .centerColumnContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .flexColumnContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .flexRowContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .flexCenterColumnContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }
      
      .flexCenterRowContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }

      .fillWidth {
        width: 100%;
      }

      .fillHeight {
        height: 100%;
      }
      
      .allowInput {
        pointer-events: auto;
      }

      .disableInput {
        pointer-events: none;
      }

      .noOverflow {
        overflow: hidden;
      }
    `}
    />
  )
}

export default GlobalStyles;
