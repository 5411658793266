// ----------------------------------------------------------
// Most of time I want to know the size of window in the parent
// level, and can be passed the size deeply in the child tree.
// So I create this context.
//
// Also given reference width and height, this context
// generate a rescale information.

// This context depends on the following packages:
//    npm install --save react-use-measure @juggle/resize-observer
// ----------------------------------------------------------
import { createContext, useContext } from 'react';
import useMeasure from 'react-use-measure'
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
import containFit from '../utils/containFit';

const WindowSizeContext = createContext();

function WindowSizeProvider({ referenceWidth=1080, referenceHeight=1920, children, ...remainingProps })
{  
  const [measureRef, containerRect] = useMeasure({ polyfill: window.ResizeObserver || Polyfill });
  const rescale = containFit( referenceWidth, referenceHeight, containerRect.width, containerRect.height );
  return (
    <div ref={measureRef} style={{position:'absolute',left:0,top:0,width:'100%',height:'100%'}}>
      <WindowSizeContext.Provider value={{ containerRect, rescale }} { ...remainingProps }>
        { children }
      </WindowSizeContext.Provider>
    </div>
  )
}

function WindowSizeConsumer(props) {
  return (<WindowSizeContext.Consumer {...props} />);
}

function useWindowSizeContext() {
  const context = useContext(WindowSizeContext);
  if (context === undefined) {
    throw new Error('useWindowSizeContext must be used within a WindowSizeProvider');
  }
  return context;
}

function withWindowSizeContext(referenceWidth=1080, referenceHeight=1920) {
  return (WrappedComponent) => ({...props}) => (
    <WindowSizeProvider referenceWidth={referenceWidth} referenceHeight={referenceHeight}>
      <WrappedComponent {...props} />
    </WindowSizeProvider>
  );
}

export {WindowSizeProvider, WindowSizeConsumer, useWindowSizeContext, withWindowSizeContext};
