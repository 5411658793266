// -----------------------------------------------------------------------------
// A React component which shows some generic information to warn the player
// that the browser being used does not support camera.
// -----------------------------------------------------------------------------
import React from 'react'
import styled from '@emotion/styled'
import Clipboard from 'clipboard'

const BrowserWarning = styled.div`
  position:absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
`
const BrowserWarningContainer = styled.div`
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  padding-top: 15px;
  padding-bottom: 15px;
`
const BrowserWarningUrlText = styled.div`
    color: black;
    margin-top: 10px;
`
const ClipboardButton = styled.div`
  width: 50%;
  background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
  border-radius: 3;
  border: 0;
  color: white;
  text-align: center;
  height: 48;
  padding: 10px 30px;
  boxShadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
`
const ClipboardResult = styled.div`
  color:  blueviolet;
`
const TextContainer = styled.div`
  width: 100%;
  text-align: center;
`

const CameraNotSupportedView = () => {

  const [clipboardSupported, setClipboardSupported] = React.useState(true)
  const [siteUrl, setSiteUrl] = React.useState('')
  const [copied, setCopied] = React.useState(false)
  const buttonRef = React.useRef(null)

  React.useEffect(() => {
    const thisUrl = window.location.href
    setSiteUrl(thisUrl)

    const clipboard_supported = Clipboard.isSupported()

    setClipboardSupported(clipboard_supported)

    if (clipboard_supported) {
      var btn = buttonRef.current
      var clipboard = new Clipboard(btn)
      btn.setAttribute('data-clipboard-text', thisUrl)
      clipboard.on('success', (e) => { setCopied(true); })
      // clipboard.on('error', function (e) { console.log(e) })
    }
  }, [])

  return (
    <BrowserWarning>
      {clipboardSupported ?
        <BrowserWarningContainer>
          <TextContainer><strong>你的瀏覽器不支援此程式</strong></TextContainer>
          <TextContainer><strong>Your browser does not support this program</strong></TextContainer>
          <br />
          <TextContainer>請複製此連結</TextContainer>
          <TextContainer>Copy the below link</TextContainer>
          <br />
          <BrowserWarningUrlText>{siteUrl}</BrowserWarningUrlText>
          <br />
          <ClipboardButton ref={buttonRef}>複製連結 Copy Link</ClipboardButton>
          {copied && <ClipboardResult>已複製! Copied!</ClipboardResult>}
          <br />
        </BrowserWarningContainer>
        :
        <BrowserWarningContainer>
          <TextContainer>請手動複製此連結</TextContainer>
          <TextContainer>Copy the below link manually</TextContainer>
          <br />
          <BrowserWarningUrlText>{siteUrl}</BrowserWarningUrlText>
          <br />
        </BrowserWarningContainer>
      }
      <BrowserWarningContainer>
        <TextContainer><strong>然後在以下建議瀏覽器打開此程式:</strong></TextContainer>
        <TextContainer><strong>And use the below suggested browsers for running the program:</strong></TextContainer>
        <br />
        <TextContainer>iOS系統請使用Safari瀏覽器打開</TextContainer>
        <TextContainer>For iOS devices, please use Safari</TextContainer>
        <br />
        <TextContainer>Android系統請使用Chrome瀏覽器打開</TextContainer>
        <TextContainer>For Android devices, please use Chrome</TextContainer>
      </BrowserWarningContainer>
    </BrowserWarning>
  )
}

export default CameraNotSupportedView
