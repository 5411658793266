// ------------------------------------------------------------------------
// Use a fixed, fullscreen to cover the device screen when the orientation
// is landscape.  Suitable for a project which only allows portrait mode.
// ------------------------------------------------------------------------
import { css } from '@emotion/css'
import { landToPortImg } from '../assets'

function RotateToPortraitView ({orientation})
{
  if (orientation === 'landscape') {
    return (
      <div 
        className={css`
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: white;
          z-index: 100;
          pointer-events: none;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <img src={landToPortImg} alt='rotate to portrait mode' 
          className={css`
            height: 50%;
          `}
        />
      </div>
    )
  } else {
    return null;
  }
}

export default RotateToPortraitView;
