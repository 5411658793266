import { useWindowSizeContext } from '../ccg-reactjs-libs/common/contexts/windowSizeContext.js';
import RoundCornerDiv from '../ccg-reactjs-libs/common/components/RoundCornerDiv';

import cameraButton from '../assets/ui/BTN-1.png';
import cameraButtonFlip from '../assets/ui/BTN-2.png';

const Buttons = ({ onCaptureButton, onSwitchCameraButton }) => 
{
  const cameraButtonSize = 230;
  const flipButtonSize = 150;
  
  const { rescale } = useWindowSizeContext();

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: 'absolute', 
      width: '100%', 
      height: 166/rescale.scale,
      top: 10/rescale.scale, 
      left: 0,
      // backgroundColor: 'red',
    }}>
      <div onClick={onCaptureButton} style={{
        position: 'absolute',
        top: (1920 - 176) / rescale.scale,
        width: 166/rescale.scale,
        height: 166/rescale.scale,
      }}>
        {/* <img src={cameraButtonShadow} alt="" style={{
          position: 'absolute',
          width: 666/rescale.scale,
          height: 497/rescale.scale,
        }} /> */}
        <img src={cameraButton} alt="" style={{
          position: 'absolute',
          width: 166/rescale.scale,
          height: 166/rescale.scale,
        }} />
      </div>


      <div onClick={onSwitchCameraButton} style={{
        position: 'absolute',
        right: 20/rescale.scale,
        // top: 250/rescale.scale,
        width: 166/rescale.scale,
        height: 166/rescale.scale,
      }}>
        {/* <img src={cameraButtonShadow} alt="" style={{
          position: 'absolute',
          width: 666/rescale.scale,
          height: 497/rescale.scale,
        }} /> */}
        <img src={cameraButtonFlip} alt="" style={{
          position: 'absolute',
          width: 166/rescale.scale,
          height: 166/rescale.scale,
        }} />
      </div>

      {/* <div onClick={onSwitchCameraButton} style={{
        position: 'absolute',
        right: 50/rescale.scale,
        top: 250/rescale.scale,
        pointerEvents:'auto',
      }}>
        <RoundCornerDiv color={'#404040'} width={flipButtonSize/rescale.scale}>
          <img src={cameraButtonFlip} alt="flip camera" style={{
            position: 'absolute',
            top: '20%',
            left: '20%',
            width: '60%',
            height: '60%',
          }}/>
        </RoundCornerDiv>
      </div> */}
    </div>
  )
}

export default Buttons;