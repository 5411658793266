// -----------------------------------------------------------------------------
// A React component which create a simple Flash on the screen.
// See example/CameraExample.js on how to use this.
// -----------------------------------------------------------------------------
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

const flashAnimation = keyframes`
  from { opacity: 0.75; }
  to { opacity: 0; }
`

const CameraFlash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  opacity: 0;
  pointer-events: none;
  z-index: 100;
  
  ${({ flash }) => {
    if (flash) {
      return css`
        animation: ${flashAnimation} 750ms ease-out;
      `
    }
  }}
`

export default CameraFlash;
