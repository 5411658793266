import { useState, useRef } from 'react';
import Div100vh from 'react-div-100vh';
import clsx from 'clsx';
import { useBrowserCapability, GlobalStyles } from '../ccg-reactjs-libs/common';
import { WindowSizeProvider, useWindowSizeContext } from '../ccg-reactjs-libs/common/contexts/windowSizeContext.js';
import { CameraFlash } from '../ccg-reactjs-libs/camera';
import { useSpring, animated, config } from "@react-spring/web";
import CompositeView from './CompositeView';
import VideoLoader from './VideoLoader';

import '../App.css';

import CameraView from './CameraView';
import CameraButtons from './CameraButton';
import ShareHints from './ShareHints';
import Scene from './Scene';

function Scanner({ handleChangeVideo, handleChangeQRScan, setAbleScan }) {
  const whiteFrameSize = 20;
  
  const [loadVideo, setLoadVideo] = useState(false);
  const onVideoStart = () => {
    setLoadVideo(true);
  }

  const { rescale } = useWindowSizeContext();

  const {supportCamera, iOSVersion} = useBrowserCapability();
  const [cameraUserMode, setCameraUserMode]  = useState(false);
  const cameraRef = useRef(null);

  const [isFlashing, setIsFlashing] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const handleDone = () => {
    setIsDone(true);
    console.log("done!");
  }

  const compositeRef = useRef(null);

  const previewImageRef = useRef(null);
  const [showPreview, setShowPreview] = useState(false);
  //const [animatePreview, setAnimatePreview] = useState(false);
  const [previewStyles, previewApi] = useSpring(()=>({scale:1, rotate:0}));

  // const effectLayerRef = useRef(null);

  const sceneRef = useRef(null);

  // --------------------------------------------------------
  // Camera event handlers
  // --------------------------------------------------------
  const onSwitchCameraButton = () => { setCameraUserMode(!cameraUserMode); }

  const onBackButton = () => {
     setShowPreview(false); // keep on retake
     handleChangeQRScan(true);
     handleChangeVideo();
  }

  const composite = async () => {
    // ------------------------------------------------------------------------------------------
    // We need to known the size of the viewable area, so that the final image can match
    // to what the player see on the screen.  We can either use <Measure>, or use
    // window.innerWidth (which have issues), or get the viewable size directly from cameraRef
    // because it has used <Measure> already.
    // 
    // We multiple the final resolution by window.devicePixelRatio, otherwise the resolution will be too low.
    // ------------------------------------------------------------------------------------------
    const cameraStyle  = cameraRef.current.getCameraStyle();
    const outputWidth  = cameraStyle.containerWidth  * window.devicePixelRatio; 
    const outputHeight = cameraStyle.containerHeight * window.devicePixelRatio; 
    console.log(outputWidth, outputHeight, window.devicePixelRatio)
    // -----------------------------------------------------------------------
    // on IOS, DataURL can be shared through browser's context menu; blob cannot.
    // on Andriod, using DataURL can share, but not download; using blob will be better. 
    // -----------------------------------------------------------------------
    const returnBlob = false;//(iOSVersion[0] === 0 ? true : false);

    const blob = await compositeRef.current.composite( outputWidth, outputHeight, 
    [
      { 
        source   : cameraRef.current.getVideoDOM(),
        srcWidth : cameraRef.current.getCameraWidth(),
        srcHeight: cameraRef.current.getCameraHeight(),
        // mirror   : cameraUserMode,
      },
      ...sceneRef.current.getLayers(outputWidth, outputHeight),
    ],
    returnBlob
    );

    previewImageRef.current.width  = outputWidth;
    previewImageRef.current.height = outputHeight;
    
    if (returnBlob) {
      previewImageRef.current.src = URL.createObjectURL(blob);
    } else {
      previewImageRef.current.src = blob;
    }

    setTimeout(()=>{ setShowPreview(true); },200);
    previewApi.start({scale:1, rotate: 0, immediate:true});
    previewApi.start({scale:0.7, rotate: -5, config:config.slow}); // delay until the Flash effect gone
  }

  const onCaptureButton = async () =>
  {
    setIsFlashing(true);
    await composite();
    console.log(compositeRef.current)
    //setTimeout(()=>{ composite(); }, 10);
  }

  // dummy thing
  const [dummyCamera, setDummyCamera] = useState(true);
  const [realCamera, setRealCamera] = useState(false);
  const onDummyCameraStart = () => {
    setDummyCamera(false);
    setRealCamera(true);
    // setTimeout(()=>{
    //   setLoadVideo(true);
    // },500);
  }

  if (rescale.containerWidth < 1) return null;
  //if (isPreloadingImages) return <FullPageSpinner backgroundColor={'rgba(0,0,0,0.5)'} />;
  return (
      <div style={{overflow: "hidden"}}>
        {/* <CompositeView> is a hidden canvas */}
        <CompositeView ref={compositeRef} /> 
 
        {/* Effects are drawn to this layer */}
        {/* <canvas ref={effectLayerRef} hidden style={{position: 'absolute', left:0, rigth:0, top:0, bottom:0}}/> */}
        {dummyCamera && <CameraView onVideoStart={onDummyCameraStart} /> }
        {!loadVideo && <VideoLoader />}
        {realCamera && <CameraView ref={cameraRef} user={cameraUserMode} onVideoStart={onVideoStart} /> }

        {/* {!showPreview && <Scene ref={sceneRef} /> } */}
        {loadVideo && <Scene ref={sceneRef} handleDone={handleDone} isDone={isDone} />}

        {/* photo preview layer -- should use "visibility" as I need the ref */}
        <div className={clsx('fillParent', 'disableInput')} style={{backgroundColor:'rgba(0,0,0,0.7', visibility: showPreview ? 'visible':'hidden'}}>
          <animated.div style={{
            position: 'absolute',
            left: -1 * whiteFrameSize/rescale.scale,
            top: -1 * whiteFrameSize/rescale.scale,
            width : rescale.containerWidth  + 2 * whiteFrameSize/rescale.scale,
            height: rescale.containerHeight + 2 * whiteFrameSize/rescale.scale,
            backgroundColor: 'white',
            ...previewStyles,
          }}>
            <img alt="" ref={previewImageRef} style={{
              position: 'absolute',
              left: whiteFrameSize/rescale.scale,
              top : whiteFrameSize/rescale.scale,
              width: rescale.containerWidth,
              height: rescale.containerHeight,
              pointerEvents: 'auto'
            }}/>
          </animated.div>
        </div>

        {/* camera button layer */}
        {(!showPreview && isDone) &&
          <CameraButtons onCaptureButton={onCaptureButton} onSwitchCameraButton={onSwitchCameraButton} />
        }

        { showPreview && <ShareHints onBackButton={onBackButton} /> }

        <CameraFlash flash={isFlashing} onAnimationEnd={() => setIsFlashing(false)} />
      
        {/* <Reference src={require('assets/references/ref-9.jpg')} width={1080} height={1920} /> */}
 
      </div>
  );
}

const ScannerWrappedWithProvider = () => {
  return (
    <Div100vh>
      <WindowSizeProvider referenceWidth={1080} referenceHeight={1920}>
          <GlobalStyles />
          <Scanner />
      </WindowSizeProvider>
    </Div100vh>
  )
}

export default ScannerWrappedWithProvider;

export { Scanner };