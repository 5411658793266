import { BGimage, startBtn, logo, contBtn, intro } from "../../assets";
import { useState } from 'react'
import { useWindowSizeContext, WindowSizeProvider } from '../../ccg-reactjs-libs/common/contexts/windowSizeContext.js';
import { GlobalStyles, useOrientation, RotateToPortraitView } from '../../ccg-reactjs-libs/common';

import Div100vh from 'react-div-100vh';

const NotFound = () => {
    const { rescale } = useWindowSizeContext();
    const [showHome, setShowHome] = useState(true);
    const orientation = useOrientation();
    const handleChangeHome = () => {
        setShowHome(prev => !prev);
    }
    return ( 
        <div  style={{ overflow: "hidden", position: "absolute", ...rescale.domStyle }}>
            <img src={BGimage} alt="BG" style={{ position: "absolute", width: "100%", height: "100%" }} />
            <>
                <img src={logo} alt="logo" style={{ position: "absolute", width: 1003/rescale.scale, height: 640/rescale.scale, top: "20%"}} />
                <div onClick={handleChangeHome} style={{ position: "absolute", width: 500/rescale.scale, height: 166/rescale.scale, top: "57%", left: "27%" }}>
                    請掃瞄指定QR Code
                </div>
            </>
            <RotateToPortraitView orientation={orientation} />
        </div>
     );
}

const NotFoundWrappedWithProvider = () => {
    return (
      <Div100vh>
            <WindowSizeProvider referenceWidth={1080} referenceHeight={1920}>
                <GlobalStyles />
                <NotFound />
            </WindowSizeProvider>
      </Div100vh>
    )
  }

export default NotFoundWrappedWithProvider;