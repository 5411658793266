import styled from '@emotion/styled';

const FullScreenContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${props => props.zIndex};
  background-color: ${props => props.backgroundColor}; 
  pointer-events: none;
`;

export default function FullScreenMask({ backgroundColor = 'black', zIndex = 'auto' }) {
  return <FullScreenContainer backgroundColor={backgroundColor} zIndex={zIndex} />
}