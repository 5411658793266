// ------------------------------------------------------------------------
// This hook return the current orientation of a device.
// TODO: so many different ways to check the orientation.  Which one is
// more stable?
// ------------------------------------------------------------------------
import { useState, useEffect } from 'react'
import DevLog from '../utils/DevLog'

export default function useOrientation ()
{
  const [orientation, setOrientation] = useState('portrait')
  const [orientationMode, setOrientationMode] = useState(2)

  const onOrientationChange = (event) =>
  {
    var _orientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
    
    if (orientationMode === 0) {
      // eslint-disable-next-line
      const [_screenOrientation, _type] = window.screen.orientation.type.split('-')
      _orientation = _screenOrientation
    } else if (orientationMode === 1) {
      var angle = window.orientation
      _orientation = Math.abs(angle) === 90 ? 'landscape' : 'portrait'
    } else if (orientationMode === 2) {
      _orientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
      setOrientation(_orientation)
    }
    
    if (_orientation !== orientation) { DevLog('[useOrientation] onOrientationChange: ' + _orientation); }

    setOrientation(_orientation)
  }

  useEffect(() => {
    // if ((window.screen.orientation) && ('onchange' in window.screen.orientation)) {
    //   console.log('Using screen.orientation.onchange')
    //   window.screen.orientation.addEventListener('change', onOrientationChange)
    //   setOrientationMode(0)
    // } else if ('onorientationchange' in window) {
    //   console.log('Using window.onorientationchange')
    //   window.addEventListener('orientationchange', onOrientationChange)
    //   setOrientationMode(1)
    // } else {
      // console.log('Using resize event')
      window.addEventListener('resize', onOrientationChange)
      setOrientationMode(2)  // so far seems this method is more stable...
    // }

    // set initial orientation based on window's width and height
    var _orientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
    setOrientation(_orientation)

    DevLog('[useOrientation] setInitialOrientation: ' + _orientation)

    // eslint-disable-next-line
  }, [])

  return orientation
}
