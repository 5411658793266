// -------------------------------------------------------------------------
// This component contains a hidden canvas, and provides function for
// compositing several canvas/video together.
// -------------------------------------------------------------------------
import { useRef, forwardRef, useImperativeHandle } from 'react'
import { coverFit, DevLog } from '../ccg-reactjs-libs/common'

const CompositeView = forwardRef((props, ref) => {

  const compositeCanvasRef = useRef(null)

  useImperativeHandle(ref, () => (
  {
    composite: async (width, height, layers = [], returnBlob = false) =>
    {
      if (compositeCanvasRef.current === null) return null; // just to play safe

      DevLog('[CompositeView] composite size=' + width + 'x' + height);

      compositeCanvasRef.current.width  = width;
      compositeCanvasRef.current.height = height;
 
      const context = compositeCanvasRef.current.getContext('2d');

      for (let i=0; i < layers.length; i++)
      {
        DevLog('[CompositeView] layer ' + i + ' size=' + layers[i].srcWidth + "x" + layers[i].srcHeight);
        
        const {x, y, scale} = coverFit(layers[i].srcWidth, layers[i].srcHeight, width, height);
        if (layers[i].mirror === true) {
          context.save();
          // context.setTransform(-1, 0, 0, 1, layers[i].srcWidth, 0);
          context.scale(-1, 1);
          context.translate(-layers[i].srcWidth, 0);
          context.drawImage(
            layers[i].source,
            -1 * x * scale,
            -1 * y * scale, 
            width  * scale,
            height * scale,
            0, 0, width, height
          );
          context.restore();
        } else {
          context.drawImage(
            layers[i].source,
            -1 * x * scale,
            -1 * y * scale, 
            width  * scale,
            height * scale,
            0, 0, width, height
          );
        }
      }

      // -----------------------------------------------------------------------
      // on IOS, DataURL can be shared through browser's context menu; blob cannot.
      // on Andriod, using DataURL can share, but not download; using blob can
      // download but not share. 
      // -----------------------------------------------------------------------
      if (returnBlob) {
        const returnBlob = await new Promise(resolve => compositeCanvasRef.current.toBlob(blob => resolve(blob), 'image/jpeg', 1));
        return returnBlob;
      } else {
        const dataURL = compositeCanvasRef.current.toDataURL('image/jpeg');
        return dataURL;
      }
    },
    getCanvas: () => { return compositeCanvasRef; },
  }));

  return (
    <canvas ref={compositeCanvasRef} hidden style={{position: 'absolute', left:0, rigth:0, top:0, bottom:0}}/>
  )
});

export default CompositeView;