import { useWindowSizeContext } from '../ccg-reactjs-libs/common/contexts/windowSizeContext.js';
import RoundCornerDiv from '../ccg-reactjs-libs/common/components/RoundCornerDiv';

import backButton from '../assets/ui/back.png';

const ShareHints = ({ onBackButton }) => 
{
  const { rescale } = useWindowSizeContext();

  const backButtonSize = 200;

  return (
    <div className='centerRowContainer' style={{
      position:'absolute',
      left: 0,
      bottom: 10,
      width: '100%',
      height: 250/rescale.scale,
      justifyContent: 'space-between',
      pointerEvents: 'none'
    }}>
      <div onClick={onBackButton} style={{
        width: backButtonSize/rescale.scale,
        height: backButtonSize/rescale.scale,
        marginLeft: 50/rescale.scale,
        pointerEvents:'auto',
      }}>
        <img src={backButton} alt="" style={{
          width: backButtonSize/rescale.scale,
          height: backButtonSize/rescale.scale,
          transform: 'translate3d(0, 0, 0)',
          mixBlendMode: 'screen',
          zIndex:500,
        }} />
      </div>

      <div className='centerColumnContainer' style={{
        width: rescale.containerWidth - (backButtonSize - 100)/rescale.scale,
        height: '100%'
      }}>
        <RoundCornerDiv className='centerRowContainer' color={'rgba(18,72,96,0.7)'} width={800/rescale.scale} height={150/rescale.scale} containerStyle={{
          fontSize: 50/rescale.scale,
          color: 'white'
        }}>
          {"請長按相片以作儲存"}
        </RoundCornerDiv>
      </div>
    </div>
  )
}

export default ShareHints;