import webmVideo1 from './video/01-NEW-vp9-chrome.webm';
import webmVideo2 from './video/02-NEW-vp9-chrome.webm';
import webmVideo3 from './video/03-NEW-vp9-chrome.webm';
import webmVideo4 from './video/04-NEW-vp9-chrome.webm';
import webmVideo5 from './video/05-NEW-vp9-chrome.webm';
import webmVideo6 from './video/05-NEW-Heart-vp9-chrome2.webm';

import mp4Video1 from './video/01-NEW-hevc-safari.mp4';
import mp4Video2 from './video/02-NEW-hevc-safari.mp4';
import mp4Video3 from './video/03-NEW-hevc-safari.mp4';
import mp4Video4 from './video/04-NEW-hevc-safari.mp4';
import mp4Video5 from './video/05-NEW-hevc-safari.mp4';
import mp4Video6 from './video/05-NEW-Heart-hevc-safari2.mp4';

import BGimage from './ui/BG.png';
import contBtn from './ui/BTN-Cont.png';
import startBtn from './ui/BTN-Strat.png';
import intro from './ui/Intro.png';
import logo from './ui/LOGO.png';
import capBtn from './ui/Cap.png';
import clickText from './ui/TEXT.png';
import sunPlanImg from './ui/sun_plan_35.png';

import landToPortImg from './ui/rotate-new.png'

import bgm from './bgm/BGM-Loop.mp3';

const webmVideoList = [webmVideo1, webmVideo2, webmVideo3, webmVideo4, webmVideo5];
const mp4VideoList = [mp4Video1, mp4Video2, mp4Video3, mp4Video4, mp4Video5];

const qrCodeLinkList = ['https://dsd.hk/code/video1', 'https://dsd.hk/code/video2', 'https://dsd.hk/code/video3', 'https://dsd.hk/code/video4', 'https://dsd.hk/code/video5'];

export {
  webmVideoList,
  mp4VideoList,
  webmVideo6,
  mp4Video6,
  qrCodeLinkList,
  BGimage,
  contBtn,
  startBtn,
  intro,
  logo,
  capBtn,
  clickText,
  landToPortImg,
  sunPlanImg,
  bgm,
};