// -----------------------------------------------------------------------------
// A React component which call useUserMedia() to create a camera view.  Since camera
// resolution is given by device, it may not be the same as the viewable area.
// We will use "coverFit" to cover-fit the camera image onto the viewable area.
// -----------------------------------------------------------------------------
import { useState, useRef, useMemo, forwardRef, useImperativeHandle } from 'react'
import styled from '@emotion/styled'
import { useUserMedia } from '../hooks/useUserMedia'
import DevLog from '../../common/utils/DevLog'
import coverFit from '../../common/utils/coverFit'

import useMeasure from 'react-use-measure'
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';

const FullscreenContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Video = styled.video`
  position: absolute;
  pointer-events: none;
  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
`

// -----------------------------------------------------------------------------
// https://stackoverflow.com/questions/37949981/call-child-method-from-parent
// -----------------------------------------------------------------------------
const CameraView = forwardRef(({ cameraWidth = 1280, cameraHeight = 720, user = false, onVideoStart = null, onVideoResize = null }, ref) => {
  
  const requestOptions = useMemo(() => { return {
    audio: false,
    video: {
      width: { min: 640, ideal: cameraWidth, max: 1920 },
      height: { min: 400, ideal: cameraHeight, max: 1080 },
      facingMode: user ? 'user' : 'environment'
    }
  }}, [cameraWidth, cameraHeight, user]);

  const [videoSize, setVideoSize] = useState({width:0, height:0});
  const videoRef = useRef(null);
  
  // -------------------------------------------------------------
  // this following hook will start streaming from the camera
  // -------------------------------------------------------------
  const mediaStream = useUserMedia(requestOptions, videoRef.current);

  // ----------------------------------------------------------------------------------------
  // when browser orientation changed, the video resolution will also changed.
  // ----------------------------------------------------------------------------------------
  function handleVideoResize () {
    let width  = videoRef.current ? videoRef.current.videoWidth : 0;
    let height = videoRef.current ? videoRef.current.videoHeight : 0;
    setVideoSize({width, height})
    if (onVideoResize) onVideoResize();
  }

  // ----------------------------------------------------------------------------------------
  // when stream is ready, trigger the play() and store the camera resolution
  // ----------------------------------------------------------------------------------------
  function handleCanPlay () {
    DevLog('[CameraView] handleCanPlay: videoSize=' + videoRef.current.videoWidth + 'x' + videoRef.current.videoHeight)
    handleVideoResize();
    videoRef.current.play();
    if (onVideoStart) onVideoStart();
  }

  // ----------------------------------------------------------------------------------------
  // Whenever videoSize or containerRect changed, find a coverFitStyle for the video.
  // ----------------------------------------------------------------------------------------
  const [measureRef, containerRect] = useMeasure({ polyfill: window.ResizeObserver || Polyfill });

  const coverFitStyle = coverFit( videoSize.width, videoSize.height, containerRect.width, containerRect.height );
  
  DevLog('[CameraView] size changed: ' + containerRect.width + 'x' + containerRect.height + ' videoSize=' + videoSize.width + 'x' + videoSize.height);

  // ----------------------------------------------------------------------------------------
  // Export some function to the outside world
  // ----------------------------------------------------------------------------------------
  useImperativeHandle(ref, () => ({
    getVideoDOM: () => { return videoRef.current },
    getCameraWidth: () => { return videoRef.current.videoWidth },
    getCameraHeight: () => { return videoRef.current.videoHeight },
    getCompositeLayer: () => {
      return {
        source   : videoRef.current,
        srcWidth : videoRef.current.videoWidth,
        srcHeight: videoRef.current.videoHeight,
      };
    },
    // -------------------------------------------------------------------------------------------
    // Note that getCameraStyle() return "coverFitStyle", which is a "snapshot" and will not
    // return the *latest* value of "coverFitStyle".  So it is not useful and commented out.
    // -------------------------------------------------------------------------------------------
    // getCameraStyle: () => { return coverFitStyle },

    // -------------------------------------------------------------------------------------------
    // If we want to get the latest containerSize of this, we can get the style directly
    // from the videoRef.current object.  But do we have any better method?
    // -------------------------------------------------------------------------------------------
    getContainerSize: () => { 
      const bounds = videoRef.current.getBoundingClientRect();
      return { containerWidth: bounds.width + 2 * bounds.x, containerHeight: bounds.height + 2 * bounds.y }
    }, 

  }));

  // if (!mediaStream) { return null; } // if no camera, drawing nothing

  // useEffect(()=>{
  //   return function cleanup() {
  //     DevLog('[CameraView] cleanup');
  //     if (mediaStream) {
  //       DevLog('[CameraView] cleanup -- stop media stream');
  //       mediaStream.getTracks().forEach(track => { track.stop() })
  //     }
  //   }
  // })

  // ------------------------------------------------------------------------------------------
  // As my <FullscreenContainer> use 100% width and 100% height of the parent, 
  // the use-measure measures the size of my parent container window.
  // The size is used to find a coverFitStyle parameters, and use it to cover-fit
  // the video image.
  // ------------------------------------------------------------------------------------------
  return (
    <FullscreenContainer ref={measureRef}>
      <Video
        ref={videoRef}
        onCanPlay={handleCanPlay}
        onResize={handleVideoResize}
        autoPlay
        playsInline
        muted
        style={{ 
          ...coverFitStyle.domStyle,
          transform: 'scale(' + (user ? '-1' : '1') + ',1)'
        }}
      />
    </FullscreenContainer>
  )
})

export default CameraView;
