import { forwardRef, useImperativeHandle, useState, useRef, useEffect } from 'react';
import { useWindowSizeContext } from '../ccg-reactjs-libs/common/contexts/windowSizeContext.js';
import { DevLog } from '../ccg-reactjs-libs/common';
import { useSpring, animated } from "@react-spring/web";
import { useGesture } from '@use-gesture/react';
import { webmVideoList, mp4VideoList, clickText, webmVideo6, mp4Video6, sunPlanImg } from '../assets';
import { useParams } from 'react-router-dom';

const bigArray = [
  {videoWidth: 1920, videoHeight: 2160, initLeft: -400, initBottom: -200, stopVideo: 5.3, showText: 5400, top: "68%", left: "22%"},
  {videoWidth: 1920, videoHeight: 2160, initLeft: -430, initBottom: -460, stopVideo: 5.8, showText: 5500, top: "60%", left: "25%"},
  {videoWidth: 1920, videoHeight: 2160, initLeft: -330, initBottom: -400, stopVideo: 3.8, showText: 3900, top: "60%", left: "18%"},
  {videoWidth: 1920, videoHeight: 2160, initLeft: -500, initBottom: -100, stopVideo: 4.8, showText: 4900, top: "70%", left: "35%"},
  {videoWidth: 1920, videoHeight: 2160, initLeft: -370, initBottom: -340, stopVideo: 3.0, showText: 100, top: "60%", left: "45%"},
  {videoWidth: 800, videoHeight: 800, initLeft: 250, initBottom: 0, stopVideo: 3.0 },
]

const Scene = forwardRef(({handleDone, isDone, ...props}, ref) => 
{
  const { videoDisplay } = useParams();
  const { rescale } = useWindowSizeContext();

  const [isLoading, setIsLoading] = useState(false); // ?
  const isPlayingRef = useRef(false);
  const [showClickText, setShowClickText] = useState(false);;

  const videoRef = useRef(null);
  const videoRef2 = useRef(null);
  const imgRef = useRef(null);

  const handleClick = () => {
    if(videoRef.current && videoRef.current.currentTime < bigArray[videoDisplay].stopVideo) return;
    if(!videoRef.current.ended){
      videoRef.current.play();
      setShowClickText(false);
      if(videoRef.current.played){
        videoDisplay === "4" && videoRef2.current.play();
      }
    }
  }
  useEffect(() => {
    const timer = setInterval(() => {
      if(videoRef.current && videoRef.current.currentTime > bigArray[videoDisplay].stopVideo){
          if(!isPlayingRef.current){
            videoRef.current.pause();
            // videoDisplay === "4" && videoRef2.current.pause();
            setShowClickText(true);
            isPlayingRef.current = true;
          }
          }
        }, 200)
    
        return () => clearInterval(timer);
  }, [])

  function handleCanPlay () {
    DevLog('[Scene] handleCanPlay: videoSize=' + videoRef.current.videoWidth + 'x' + videoRef.current.videoHeight)
    videoRef.current.play();
    videoDisplay === "4" && videoRef2.current.pause();
    setIsLoading(false);
    // setVideoSize({width: videoRef.current.videoWidth, height: videoRef.current.videoHeight});  // this one will force a renderer and trigger useCoverFit()
    // if (onVideoStart) onVideoStart();
  }

  const effectLayerRef = useRef(null);
  const prepareEffectLayer = (outputWidth, outputHeight) => 
  {
    // --------------------------------------------------------------------
    // Draw the 2D image onto the effectLayerRef; the effectLayerRef should have the
    // same size outputWidth x  outputHeight, as cameraStyle already be the
    // visible area.
    // --------------------------------------------------------------------
    effectLayerRef.current.width  = outputWidth;
    effectLayerRef.current.height = outputHeight;
    const ctx = effectLayerRef.current.getContext('2d');
    
    if(videoDisplay === "1"){
      const bounds3 = imgRef.current.getBoundingClientRect();
      ctx.drawImage(
        imgRef.current, 
        bounds3.x * window.devicePixelRatio, 
        bounds3.y * window.devicePixelRatio, 
        bounds3.width * window.devicePixelRatio, 
        bounds3.height * window.devicePixelRatio
        );
      }
    const bounds = videoRef.current.getBoundingClientRect();
    ctx.drawImage(
      videoRef.current, 
      bounds.x * window.devicePixelRatio, 
      bounds.y * window.devicePixelRatio, 
      bounds.width * window.devicePixelRatio, 
      bounds.height * window.devicePixelRatio
    );
    if(videoDisplay === "4"){
      const bounds2 = videoRef2.current.getBoundingClientRect();
      ctx.drawImage(
        videoRef2.current, 
        bounds2.x * window.devicePixelRatio, 
        bounds2.y * window.devicePixelRatio, 
        bounds2.width * window.devicePixelRatio, 
        bounds2.height * window.devicePixelRatio
        );
      }
  }

  // ----------------------------------------------------------------------
  // "export" some functions to the outside world
  // ----------------------------------------------------------------------
  useImperativeHandle(ref, () => ({
    getLayers : (outputWidth, outputHeight) => {
      prepareEffectLayer(outputWidth, outputHeight);
      return [
        {
          source   : effectLayerRef.current, //sceneRef.current.getEffectCanvas(outputWidth, outputHeight),
          srcWidth : effectLayerRef.current.width, // sceneRef.current.getEffectCanvasWidth(),
          srcHeight: effectLayerRef.current.height, //sceneRef.current.getEffectCanvasHeight(),
        }
      ]
    },
  }));

  const dragRef = useRef(null);

  const [dragStyles, dragStylesApi] = useSpring(() => ({ 
    x: rescale.x + bigArray[videoDisplay].initLeft/rescale.scale, 
    y: rescale.y + rescale.h - bigArray[videoDisplay].initBottom/rescale.scale - bigArray[videoDisplay].videoHeight/rescale.scale, 
    scale: 1
  }))

  useGesture(
    {
      onDrag: ({ pinching, cancel, offset: [x, y], ...rest }) => {
        if(!isDone) return cancel()
        if (pinching) return cancel()
        dragStylesApi.start({ x, y })
      },
      onPinch: ({ origin: [ox, oy], first, movement: [ms], offset: [s, a], memo, cancel }) => {
        if(!isDone) return cancel()
        if (first) {
          const { width, height, x, y } = dragRef.current.getBoundingClientRect()
          const tx = ox - (x + width / 2)
          const ty = oy - (y + height / 2)
          memo = [dragStyles.x.get(), dragStyles.y.get(), tx, ty]
        }

        const x = memo[0] - (ms - 1) * memo[2]
        const y = memo[1] - (ms - 1) * memo[3]
        dragStylesApi.start({ scale: s, x, y })
        return memo
      },
    },
    {
      target: dragRef,
      drag: { 
        from: () => [dragStyles.x.get(), dragStyles.y.get()],
        // bounds: (state) => ({ 
        //   left:0, right: rescale.containerWidth  - (videoWidth/rescale.scale) * dragStyles.scale.get(), 
        //   top :0, bottom:rescale.containerHeight - (videoHeight/rescale.scale) * dragStyles.scale.get()
        // }),
        //rubberband: true
      },
      pinch: { 
        scaleBounds: { min: 0.5, max: 2 }, 
        rubberband: true 
      },
    }
  )

  const dragRef2 = useRef(null);

  const [dragStyles2, dragStylesApi2] = useSpring(() => ({ 
    x: rescale.x + bigArray[videoDisplay].initLeft/rescale.scale, 
    y: rescale.y + rescale.h - bigArray[videoDisplay].initBottom/rescale.scale - bigArray[videoDisplay].videoHeight/rescale.scale, 
    scale: 1
  }))

  useGesture(
    {
      onDrag: ({ pinching, cancel, offset: [x, y], ...rest }) => {
        if(!isDone) return cancel()
        if (pinching) return cancel()
        dragStylesApi2.start({ x, y })
      },
      onPinch: ({ origin: [ox, oy], first, movement: [ms], offset: [s, a], memo, cancel }) => {
        if(!isDone) return cancel()
        if (first) {
          const { width, height, x, y } = dragRef.current.getBoundingClientRect()
          const tx = ox - (x + width / 2)
          const ty = oy - (y + height / 2)
          memo = [dragStyles2.x.get(), dragStyles2.y.get(), tx, ty]
        }

        const x = memo[0] - (ms - 1) * memo[2]
        const y = memo[1] - (ms - 1) * memo[3]
        dragStylesApi2.start({ scale: s, x, y })
        return memo
      },
    },
    {
      target: dragRef2,
      drag: { 
        from: () => [dragStyles2.x.get(), dragStyles2.y.get()],
        // bounds: (state) => ({ 
        //   left:0, right: rescale.containerWidth  - (videoWidth/rescale.scale) * dragStyles.scale.get(), 
        //   top :0, bottom:rescale.containerHeight - (videoHeight/rescale.scale) * dragStyles.scale.get()
        // }),
        //rubberband: true
      },
      pinch: { 
        scaleBounds: { min: 0.5, max: 2 }, 
        rubberband: true 
      },
    }
  )
 
  return (
  <div className='fillParent' style={{pointerEvents:'none', overflow: "hidden"}}>

    {/* Effects are drawn to this layer */}
    <canvas ref={effectLayerRef} hidden style={{position: 'absolute', left:0, rigth:0, top:0, bottom:0}}/>

    <animated.div onClick={handleClick} ref={dragRef} style={{
      position: 'absolute',
      width: bigArray[videoDisplay].videoWidth/rescale.scale, 
      height: bigArray[videoDisplay].videoHeight/rescale.scale,
      transformOrigin: 'center center',
      pointerEvents: 'auto',
      touchAction: 'none',
      overflow: "hidden",
      ...dragStyles,
    }}>
      {videoDisplay === "1" && isDone && <img src={sunPlanImg} ref={imgRef} alt="SunPlan" style={{ width: 1920/rescale.scale, height: 1920/rescale.scale, position: "absolute", left: 250, top: 0 }} />}
      <div style={{ pointerEvents: "none", position: "absolute" }}>
      <video ref={videoRef} width={bigArray[videoDisplay].videoWidth/rescale.scale} height={bigArray[videoDisplay].videoHeight/rescale.scale} autoPlay playsInline onEnded={handleDone} onCanPlay={handleCanPlay}>
        <source 
          src={mp4VideoList[videoDisplay]} 
          type='video/mp4; codecs="hvc1"' />
        <source 
          src={webmVideoList[videoDisplay]} 
          type="video/webm" />
      </video>
      </div>
      {showClickText && <img src={clickText} alt="click me" style={{ position: "absolute", top: bigArray[videoDisplay].top, left: bigArray[videoDisplay].left, width: 504/rescale.scale, height: 230/rescale.scale }} />}
    </animated.div>
    {videoDisplay === "4" && 
    <animated.div onClick={handleClick} ref={dragRef2} style={{
      position: 'absolute',
      width: bigArray[5].videoWidth/rescale.scale, 
      height: bigArray[5].videoHeight/rescale.scale,
      left: rescale.x + bigArray[5].initLeft/rescale.scale,
      transformOrigin: 'center center',
      pointerEvents: 'auto',
      touchAction: 'none',
      overflow: "hidden",
      ...dragStyles2,
    }}>
      <video style={{ position: "absolute", left: 0, top: 0,  }} 
      ref={videoRef2} width={bigArray[5].videoWidth/rescale.scale} height={bigArray[5].videoHeight/rescale.scale} muted playsInline paused>
        <source 
          src={mp4Video6}
          type='video/mp4; codecs="hvc1"' />
        <source 
          src={webmVideo6} 
          type="video/webm" />
      </video>
    </animated.div>
      }
  </div>
  )
});

export default Scene;
