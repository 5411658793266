import { useState, useEffect } from 'react'
import coverFit from '../utils/coverFit'
// import DevLog from '../utils/DevLog'

/**
 * Utility function "coverFit"
 * 
 * Calculate offsets and scale to center the content in the middle of the container,
 * scale the content to cover the whole container.
 * 
 * Return:
 * x: the final content's x offset (after scaled)
 * y: the final content's y offset (after scaled)
 * w: the final content's width
 * h: the final content's height
 * scale: the scale of origial content size relative to the final size (i.e final_size * scale = original_size) 
 * containerWidth:  the container's width (for book keeping)
 * containerHeight: the container's height (for book keeping)
 * domStyle: an object that can be applied as the style of a DOM element
 **/
/*
export function coverFit (vWidth, vHeight, cWidth, cHeight)
{
  let x=0, y=0, w=0, h=0, scale=0;
  if (vWidth && vHeight && cWidth && cHeight && (vWidth > 0)&&(vHeight > 0)&&(cWidth > 0)&&(cHeight > 0)) {
    const vAspect = vWidth / vHeight
    const cAspect = cWidth / cHeight
    if (vAspect > cAspect) {
      // fit the height, offset the width
      h = cHeight
      w = Math.round(h * vWidth / vHeight)
      x = Math.round((cWidth - w) / 2)
      y = 0
      scale = vHeight / h;
    } else {
      // fit the width, offset the height
      w = cWidth
      h = Math.round(w * vHeight / vWidth)
      x = 0
      y = Math.round((cHeight - h) / 2)
      scale = vWidth / w;
    }
  }
  return {        
    x, y, w, h, scale, 
    containerWidth: cWidth, containerHeight: cHeight, 
    domStyle: {
      top   : y + 'px',
      left  : x + 'px',
      width : w + 'px',
      height: h + 'px'
    } 
  };
}
*/

// -------------------------------------------------------------------------------
// We can also use this "hook" in a React component
// -------------------------------------------------------------------------------
export default function useCoverFit (vWidth, vHeight, cWidth, cHeight)
{
  const [style, setStyle] = useState({ x:0, y:0, w:0, h:0, scale:0, containerWidth: 0, containerHeight: 0, domStyle: {} })

  useEffect(() => {
    // DevLog('[useCoverFit] content-size=' + vWidth + 'x' + vHeight + ' container-size=' + cWidth + 'x' + cHeight);
    setStyle(coverFit(vWidth, vHeight, cWidth, cHeight));
  }, [vWidth, vHeight, cWidth, cHeight])

  return style
}
