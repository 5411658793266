// -------------------------------------------------------------
// Print some log info ONLY at development time
// -------------------------------------------------------------
export default function DevLog(msg)
{
  // -----------------------------------------------------------------------------------------
  // the webpack compiler will evaluate process.env.NODE_ENV at build time and not runtime.
  // https://stackoverflow.com/questions/59929705/react-conditionally-required-css-file-always-included-in-build
  // -----------------------------------------------------------------------------------------
  if (process.env.NODE_ENV !== 'production') {
    console.log(msg);
    if (document.getElementById('debugMsg')) document.getElementById('debugMsg').innerHTML = msg;
  }
}
